
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "customer-details",
  setup() {
    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Outils de déploiement", []);
    });
  },
});
